import { Component, ReactNode } from 'react';
import { withRouter, NextRouter } from 'next/router';
import * as Sentry from '@sentry/react';
import Custom500 from 'pages/500';

interface ErrorBoundaryProps {
  children: ReactNode;
  router: NextRouter;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: globalThis.Error): void {
    Sentry.captureException(error);
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (this.props.router.asPath !== prevProps.router.asPath) {
      this.setState({ hasError: false });
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <Custom500 />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
