import React, { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Feedback } from '@hawkins/components';

import { GlobalFeedbackContext } from 'contexts/GlobalFeedbackContext';
import { TranslationNamespace } from 'types';

export const GlobalFeedback: React.FC = () => {
  const { message, variant, removeFeedback } = useContext(
    GlobalFeedbackContext
  );
  const { t } = useTranslation(TranslationNamespace.LEP);

  return (
    <Feedback
      autoHideDuration={3000}
      message={message}
      onClose={() => removeFeedback()}
      open={!!message}
      position="bottom-right"
      translate={{ 'feedback.dismiss.label': t('error_msg_close') }}
      variant={variant}
    />
  );
};
