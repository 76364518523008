import * as Sentry from '@sentry/react';
import { isDeployed, isDeployTestEnv } from 'utils';

const radar = {
  test: 'https://1:1@radarproxy.test.netflix.net/0',
  prod: 'https://1:1@radarproxy.prod.netflix.net/0',
};

type TInitSentry = {
  appName: string;
};

const RADAR_INIT_KEY = '_radar_init_';

/**
 * Initializes a Sentry instance to monitor runtime exceptions that are
 * persisted to InsightLogs/Radar (http://go/radar). We assume that we are running
 * in a browser runtime—not within NextJS SSR in Node.
 * @param appName The Spinnaker name of the app.
 */
export const initSentry = ({ appName }: TInitSentry) => {
  const isTestRunning = process.env.NODE_ENV === 'test';

  Sentry.init({
    enabled: !isTestRunning && isDeployed(),
    dsn: isDeployTestEnv() ? radar.test : radar.prod,
    autoSessionTracking: false,

    beforeBreadcrumb: (breadcrumb: any) => {
      // Any changes to breadcrumbs here
      return breadcrumb;
    },
    beforeSend: (event: any) => {
      // Any changes to events here
      return event;
    },
  });

  Sentry.setTag('nf.app', appName);

  if (!(RADAR_INIT_KEY in localStorage)) {
    Sentry.captureMessage('Hello Radar!', Sentry.Severity.Warning);
    localStorage[RADAR_INIT_KEY] = true;
  }
};
