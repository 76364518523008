
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { useEffect } from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import setLanguage from 'next-translate/setLanguage';
import { HawkinsProvider, CSSBaseline } from '@hawkins/components';
import { GlobalFeedbackProvider } from 'contexts';
import { GlobalFeedback } from 'components/GlobalFeedback';
import { rtlLocales } from 'utils/constants';
import axios from 'axios';
import { initSentry } from 'lib/analytics/sentry';
import ErrorBoundary from 'components/ErrorBoundary';
import { isBrowser } from 'utils/runtime';

if (isBrowser()) initSentry({ appName: 'lepui' });

function MyApp({ Component, pageProps }: AppProps) {
  const { locales, locale, asPath, isReady, push } = useRouter();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }

    // Try to grab the browser's language setting and set it
    // if we know that we support it.
    if (navigator) {
      const userLanguage =
        (navigator.languages && navigator.languages[0]) || navigator.language;
      if (userLanguage && locales?.includes(userLanguage)) {
        setLanguage(userLanguage);
      }
    }
  }, []);

  useEffect(() => {
    if (locale) {
      document.dir = rtlLocales.has(locale) ? 'rtl' : 'lrt';
    }
  }, [locale]);

  useEffect(() => {
    const fetchHealthCheck = async () => {
      try {
        const response = await axios.get('/api/health');
        if (response.data.maintenance) {
          push(
            {
              pathname: '/maintenance',
              query: {
                maintenance: response.data.maintenance,
              },
            },
            '/maintenance'
          );
        }
      } catch (err: any) {
        console.error('Error fetching health check: ', err.message);
      }
    };
    if (isReady) {
      fetchHealthCheck();
    }
  }, [asPath, isReady]);

  return (
    <HawkinsProvider theme="light">
      <GlobalFeedbackProvider>
        <CSSBaseline />
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
        <GlobalFeedback />
      </GlobalFeedbackProvider>
    </HawkinsProvider>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  